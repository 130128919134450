
.donate-page-container {
  padding-right: 28px;
  padding-left: 28px; /* source: https://matthewjamestaylor.com/responsive-font-size */
}

 ul {
  list-style-type: none;
  text-align: justify;
  padding-left:0;
  li {
    font-family: "AvenirNext-regular";
    color: #4d4d4d;
    b {
      font-family: "Avenir Next LT Pro, Demi";
      color: #4d4d4d;
    }
  }
}

.intro-info-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    padding-left: 10rem;
    padding-right: 10rem;
    margin-bottom: 2.5rem;
  }
  p {
    text-align: justify;
    color: #4d4d4d;
    b {
      font-family: "Avenir Next LT Pro, Demi";
      color: #4d4d4d
    }
  }
}

.payment-info-containers {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 8rem;
  }
}
h2.service__title {
  line-height: 1.2;
}
.credit-card-info-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top:1rem;
  gap: 0.5rem;
  h3 {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    margin-top:3rem;
    gap: 2rem;
  }
  img {
    margin-bottom: auto;
  }
  
}
.check-info-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top:1rem;
  gap: 0.5rem;
  h3 {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    margin-top:3rem;
    gap: 2rem;
  }
  img {
    margin-bottom: auto;
  }
}

.donation-options {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.disclaimer {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.donation-options-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}

.daf-info-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top:1rem;
  gap: 0.5rem;
  h3 {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    margin-top:3rem;
    gap: 2rem;
  }
}

