.home_change_label {
 color: var(--main-orange)
}

.home_scrolling_text_container {
    overflow: hidden;
    height: 1.5em; /* Adjust the height as needed */
    position: relative;
  }
  
  .home_scrolling_text {
    animation: scroll 10s linear infinite;
    transform: translateY(0);
    display: inline-block;
  }
  
  .home_scrolling_text span {
    display: block;
    height: 1.5em; /* Match the container's height */
    line-height: 1.5em; /* Match the container's height */
  }
  
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

.banner__subtitle_paragraph{
    display: flex;
    justify-content: center;
    font-size: 24px;
    gap:1rem
}

.banner__subtitle_paragraph label{
    font-family: "Avenir Next LT Pro, Regular";
    color: var(--main-gray);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
}

.banner__sub_paragraph{
    font-family: "Avenir Next LT Pro, Regular";
    color: var(--main-gray);
    font-size: 24px;
    
}