.compareContainer {
    position: fixed;
    max-height: 0;
    bottom: 0;
  
    transition: max-height 1s;
    transition-timing-function: ease;
 
}


.showContainer {
    max-height: 100vh;
}

.compareContainerPadding {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1254px;
    position: relative;
}

.compareContainer .advisorImage{
    
    max-height: 15vw;
}
.compareContainer .footer {
    background: #ffffff;
}



 .compareContainer thead tr th div{
  max-width: 20vw;
 }
 


.notDisclosable{
    color: #4d4d4d;
    font-size: 15px;
    font-family: "Avenir Next LT Pro, Demi";
    padding: 30px;
    border-left: 1px solid #a7a7a6;
}