.get-started-form-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-areas:
    "header header"
    "input-field input-field";

    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
    position: relative;
    padding: 1rem;
    border-radius: 6px;
}
.get-started-form-container .form-control.field-focus + label{
    top: 12px;
    margin-top: 0;
    line-height: 1;
    font-size: 11px;
    font-weight: 600;

}
.advisor-profile-picture__container {
    display: grid;
    grid-area: header;
    column-gap: 10px;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
    "picture-file-input profile-picture"
}
.advisor-profile-picture__container img{
    height: 15vh;
    justify-self: end;
}

.advisor-profile-picture__container input[type="file"] {
    opacity: 0;
    z-index: 2;
    position: absolute;
    cursor: pointer;
    border: none;
}
.get-started-form-input{
    grid-area: input-field
}

.social-media-label {
    max-width: 80%;
}
@media only screen and (max-width: 480px) {
  
    .social-media-label {
        max-width: 50%;
    }
    .SM-links-container {
        height: 12rem;
    }
}