.texteditor .tiptap > * + * {
  margin-top: 0.75em;
}
.texteditor .tiptap ul,
.tiptap ol {
  padding: 0 1rem;
}
.texteditor .tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}
.texteditor .tiptap code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.texteditor .tiptap pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.texteditor .tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.texteditor .tiptap img {
  max-width: 100%;
  height: auto;
}
.texteditor .tiptap blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.texteditor .tiptap hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
.texteditor .tiptap {
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 0.3rem;
  background: #fff;
  color: #000;
}
.texteditor .menubar {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 0.3rem;
  background: #fff;
  color: #000;
  font-size: 1rem;
  font-family: inherit;
}

.texteditor div.menubar > button,
div.menubar > input,
div.menubar > select {
  font-size: inherit;
  font-family: inherit;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
}

.texteditor .menubar button.is-active {
  background: black;
  color: #fff;
}
