.button {
  display: flex;
  text-align: center;
  align-items: center;
  font-family: "Avenir Next LT Pro, Demi";
  text-decoration: none;
  padding: 12px 13px 12px 30px;
  transition: all 0.2s ease-in;
  border: 2px solid transparent;
  border-radius: 10px;
  margin: 0;
  font-size: 18px;
}
.button:hover {
  text-decoration: none;
}

.chevron {
  width: 26px;
  padding-left: 13px;
}

.chevron path {
  fill: #ffffff;
}

.primary-button {
  color: #ffffff;
  background-color: var(--main-orange);
}

.primary-button:hover {
  border: 2px solid var(--main-gray);
  color: var(--main-gray);
  background-color: var(--main-gray);
  color: #ffffff;
}

.secondary-button {
  border: 2px solid var(--main-orange);
  color: var(--main-orange);
  background-color: #ffffff;
}

.secondary-button:hover {
  border: 2px solid var(--main-gray);
  color: var(--main-gray);
  background-color: var(--main-gray);
  color: #ffffff;
}

.secondary-button .chevron path {
  fill: var(--main-orange);
}
.secondary-button:hover .chevron path {
  fill: #ffffff;
}
