.create-account-container .back .homelink__chevron {
    transform: rotate(180deg);
    filter: 
        invert(42%)
        sepia(0%)
        saturate(3%)
        hue-rotate(134deg)
        brightness(104%)
        contrast(90%);
}

.create-account-container .back {
    background-color: white;
    border: 1px solid #707070;
    color: #707070;
}

.create-account-container .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}
