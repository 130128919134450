
/* Admin Dashboard */
.admin-dashboard-container {
    background-color: #F7F7F7;
  }
  
  .admin-dashboard-container .admin-content-container {
    padding: 0 15px 50px;
  }
  
  .admin-dashboard-container .admin-content-container > .row {
    margin: 0 !important;
  }
  
  .admin-dashboard-container .admin-content-container .filtered-content-container {
    padding: 0 15px;
  }
  
  .admin-dashboard-container .advisor-info-container {
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-bottom: 10px;
  }
  
  .admin-dashboard-container .advisor-info-container .advisor-info {
    padding-right: 50px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
    padding-left: 0;
  }
  
  .admin-dashboard-container .advisor-info-container .advisor-info p {
    font-size: 1rem;
    line-height: 1.63;
    color: #2c2c2c;
    margin: 0;
  }
  
  .admin-dashboard-container .advisor-info-container .advisor-info span {
    font-weight: 600;
    color: #e0601e;
    display: block;
    text-transform: capitalize;
  }
  
  .admin-dashboard-container .advisor-info-container .advisor-info .firm-name {
    color: #a3a3a3;
    font-weight: 500;
  }
  
  .admin-dashboard-container .advisor-info-container .button-action {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  
  .admin-dashboard-container .advisor-info-container .button-action button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    font-weight: 600;
    font-size: 1rem;
    color: #e0601e;
    border: 2px solid #e0601e;
    padding: 12px 10px;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-transform: capitalize;
    text-decoration: none;
    background: none;
  }
  
  .admin-dashboard-container .advisor-info-container .button-action button.btn-edit-profile {
    margin-left: 10px;
  }
  
  .admin-dashboard-container .advisor-info-container .button-action button:hover {
    background-color: #e0601e;
    color: #FFFFFF;
  }

.advisor-info-without-action .advisor-info {
    max-width: 100% !important;
    -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
    padding-right: 0 !important;
  }