
.dashboard-nav-filter {
    padding: 40px 0;
  }
  
  .dashboard-nav-filter ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  
  .dashboard-nav-filter ul li {
    float: left;
    margin-right: 20px;
  }
  
  .dashboard-nav-filter ul li:last-child {
    margin-right: 0;
  }
  
  .dashboard-nav-filter ul li button {
    text-decoration: none;
    background: none;
    border: none;
    padding: 3px;
    font-weight: 600;
    font-size: 16px;
    color: #a3a3a3;
  }
  
  .dashboard-nav-filter ul li button:hover, .dashboard-nav-filter ul li button:active {
    color: #e0601e;
  }
  
  .dashboard-nav-filter ul li.always button {
    color: #e0601e;
  }