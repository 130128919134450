header {
    background-color: #FFFFFF;
    position: relative;
    z-index: 9;
  }
  
  header .navbar {
    padding: 2.5rem 1rem;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
  }
  
  header a, header button.navbar-brand {
    background: none;
    border: none;
  }
  
  header a img, header button.navbar-brand img {
    max-width: 250px;
    display: none;
  }
  
  header a img.logo-home, header button.navbar-brand img.logo-home {
    display: none;
  }
  
  header a img.logo, header button.navbar-brand img.logo {
    display: block;
  }
  
  header a.no-redirect, header button.navbar-brand.no-redirect {
    cursor: initial;
    pointer-events: none;
  }
  
  header .nav-right {
    border-color: transparent;
    color: #e0601e;
  }
  
  header .nav-right .navbar-toggler-bar {
    border: 1px solid #e0601e;
    width: 20px;
    height: 2px;
    margin: 5px 0;
  }
  
  header .nav-right button {
    text-decoration: none;
    border: none;
    color: #e0601e;
    background: none;
  }
  
  header .nav-right button:hover {
    color: #e0601e;
  }
  
  header .nav-right a {
    color: #e0601e;
    padding: 0.8rem 1.25rem;
    text-decoration: none;
    font-family: "Lato", sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
  }
  
  header .nav-right a.with-border:hover {
    background-color: #e0601e;
    color: #FFFFFF;
  }
  
  header .nav-right a.with-border {
    border: 2px solid #e0601e;
    margin-left: 1.35rem;
    padding: 0.8rem 2rem;
  }


li.donate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
li.donate label.button {
    display: flex;
    color: #65B5B1;
    border: #65B5B1 1px solid;
    border-radius: 6px;
    font-size: 14px;
    padding: 6px 8px;
    cursor: pointer;
    gap: 6px;
    margin: 0;
    align-items: center;
    transition: color 300ms ease;
}
li.donate label.button svg {
    width: 14px;
    height: 14px;
}
li.donate label.button svg path {
    fill: #65B5B1;
}

li.donate label.button:hover {
    color: white;
    background-color: #65B5B1;
}
li.donate label.button:hover svg path {
    fill: white;
}

li.donate {
    padding-left: 30px;
}
li.donate input {
    display: none;
}
li.donate input:checked ~ .donate__modal {
    pointer-events: all;
    opacity: 1;
}
li.donate .donate__modal {
    opacity: 0;
    display: flex;
    transition: opacity 200ms ease;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}
.donate__modal .donate__modal__content {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;  
    margin-right: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 582px;
    height: 449px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 10px #4D4D4D66;
    border-radius: 10px;
    opacity: 1;
    position: relative;
    z-index: 2;
    padding: 22px;
}

.donate__modal label.background { 
    background: #4D4D4D 0% 0% no-repeat padding-box;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.donate__modal .donate__modal__content h4 {
    text-align: left;
    letter-spacing: 1.6px;
    color: #4D4D4D;
    font-size: 16px;
    margin-bottom: 14px;
    line-height: 18px;
}
.donate__modal .donate__modal__content p {
    text-align: left;
    letter-spacing: 0px;
    color: #4D4D4D;
    line-height: 24px;
    font-size: 16px;
}
.donate__modal .donate__modal__content a.homelink {
    padding: 12px 42px;
    font-size: 18px;
    text-align: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    border-width: 1px;
    line-height: 1.2;
    margin-top: 65px;
}
.donate__modal .donate__modal__content label.close {
    width: 28px;
    height: 28px;
    margin-bottom: 32px;
    margin-left: auto;
    cursor: pointer;
}
.donate__modal .donate__modal__content label.close svg {
    width: 28px;
    height: 28px;
}

.account__dropdown {
    display: flex;
    align-items: center;
    padding-left: 30px;
    position: relative;
}
.account__dropdown .account {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    background-color: white;
    color: #707070;
    transition: box-shadow 200ms ease, border-color 200ms ease;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: pointer;
}
.account__dropdown .account.active {
    border-color: #ED742E;
    box-shadow: 0px 0px 12px #ED742E66;
}
.account__dropdown .modal__dropdown {
    right: 0;
    top: 80px;
    width: 306px;
    position: absolute;
    background-color: white;
    border: 1px solid #707070;
    border-radius: 24px;
    min-height: 184px;
    overflow: hidden;
    display: flex;
    opacity: 0;
    flex-direction: column;
    pointer-events: none;
    transition: opacity 200ms ease;
    z-index: 10;
}

.account__dropdown .account.active + .modal__dropdown {
    opacity: 1;
    pointer-events: all;
}

.account__dropdown .modal__dropdown .logout {
    width: 100%;
    background-color: #F6F6F6;
    border-top: 1px solid #707070;

    min-height: 48px;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #4D4D4D;
    font-weight: 600;
}

.account__dropdown .modal__dropdown .content {
    padding: 16px;
}

.account__dropdown .modal__dropdown .content h4 {
    font-size: 16px;
    line-height: 18px;
    color: #4D4D4D;
    margin: 0;
    margin-bottom: 8px;
    text-transform: capitalize;
    padding-left: 4px;
}

.account__dropdown .modal__dropdown .content h5 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 16px;
    padding-left: 4px;
}

.account__dropdown .modal__dropdown .content hr {
    border-color: #707070;
}

.account__dropdown .modal__dropdown .switch--link {
    gap: 4px;
    display: flex;
    font-size: 14px;
    color: #A7A7A6;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
}

.account__dropdown .modal__dropdown .switch--link img {
    margin-top: -4px;
}


