.blog-post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2rem auto;
    padding: 1rem 1rem;
    box-sizing: border-box;
    font-size: calc(15px + 0.390625vw); /* source: https://matthewjamestaylor.com/responsive-font-size */
   
    @media (min-width: 768px) {
        width: 80%;
    }

    @media (min-width: 992px) {
        width: 70%;
    }
    @media (min-width: 1200px) {
        width: 60%;
    }
    @media (min-width: 1600px) {
        width: 50%;
    }
}
.article-title {
    font-weight: 600;
    margin-bottom: 1rem;
}
.article-author {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}
a {
    color: var(--main-orange);
    text-decoration: none;
}
.orange-highlight {
    background-color: #ff6b0038;
    border-radius: 1rem;
    padding: 15px 35px;
   
}