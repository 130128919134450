.container {
  /*position: relative;*/
  height: 21px;
  background-color: #A7A7A6;
  padding-right: 6px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.input {
  /*width: 240px;*/
  /*height: 30px;*/
  /*padding: 10px 20px;*/
  /*font-family: Helvetica, sans-serif;*/
  /*font-weight: 300;*/
  /*font-size: 16px;*/
  /*border: 1px solid #aaa;*/
  /*border-radius: 4px;*/
  height: 15px;
  margin-top: 3px;
  border-radius: 3px;
  font: normal normal normal 8px/24px "Avenir Next LT Pro, Demi";
  border: none;
}

.inputFocused {
  outline: none;
}

.inputOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  right: 0px;
}

@media screen and (max-width: 1075px){
  .suggestionsContainerOpen {
    left: 0;
    top: 75px;
  }
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.suggestionHighlighted {
  background-color: #ddd;
}
