.modal-dialog {
    width: 100% !important;
    margin: 0 !important;
}
.modal-open .modal .modal-dialog .modal-content {
    background-color: rgba(54, 54, 50, 0.82) !important;
}
.modal-open .modal {
    align-items: flex-start;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 100% !important;
        margin: 0 !important;
     }
}