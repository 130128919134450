.sorters-container{
    display: flex;

    flex-flow: row;
    justify-content: space-around;
    max-width: 30vw;
}
.sortSelected{
    background: #A7A7A6 0% 0% no-repeat padding-box !important;
    color: #FFFFFF !important;
}

.sortDistanceSelected {
    background: #A7A7A6 0% 0% no-repeat padding-box !important;
    color: #FFFFFF !important;
    border-radius: 0 !important;
}

.sort-component-wrapper {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    align-items: baseline;
}

.sort-component-wrapper {
    display: flex;
    justify-content: flex-end;
}

.sort-component-wrapper label {
    font: normal normal normal 14px/18px "Avenir Next LT Pro, Demi";
    margin-bottom: 0;
    letter-spacing: 0.55px;
    color: #4D4D4D;
    padding-right: 20px;
    text-transform: uppercase;
}

/*.sort-component-wrapper img {*/
/*    height: 11px;*/
/*    margin-top: 5px;*/
/*    margin-right: 7px;*/
/*}*/

.sort-buttons-wrapper {
    display: flex;
    flex-flow: wrap;
    position: relative;
}

.sort-buttons-wrapper button {
    border: none;
    border-top: 1px solid #A7A7A6;
    border-bottom: 1px solid #A7A7A6;
    border-right: 1px solid #A7A7A6;
    font: normal normal normal 14px/18px "Avenir Next LT Pro, Demi";
    letter-spacing: 0px;
    color: #4D4D4D;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 21px;
    line-height: 18px;
    display: block;
}

.sort-buttons-wrapper input {
    display: block;
}

.sort-buttons-wrapper > button:first-of-type {
    border-left: 1px solid #A7A7A6;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.sort-buttons-wrapper > button:last-of-type {
    border-right: 1px solid #A7A7A6;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 3px;
}

.zip-distance-wrapper {
    display: flex;
}

.sort-buttons-wrapper input {
    color: #4D4D4D;
}