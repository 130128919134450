.card {
  margin-bottom: 40px;
  margin-top: 0 !important;
}

.card a {
  text-decoration: none;
  width: 100%;
}

.card .uk-card {
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 3px 3px 12px #a6a4a459;
  box-shadow: 3px 3px 12px #a6a4a459;
}

.card .image {
  width: 100%;
  height: 185px !important;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}

.card .body {
  padding: 18px 14px;
  padding-bottom: 0;
}

.card .name {
  margin-bottom: 7px;
  color: #4d4d4d;
  font-size: 20px;
  line-height: 20px;
  font-family: "Avenir Next LT Pro, Demi";
}

.card .firm {
  margin: 0;
  color: #4d4d4d;
  font-family: "Avenir Next LT Pro, Regular";
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 0;
  line-height: 16px;
}

.card .footer {
  padding: 0 15px 6px;
  text-align: center;
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 100%;
}

.card .separator {
  margin-bottom: 7px;
  margin-top: 14px;
  border-top: 1px solid #e9e9e8 !important;
}

.card .btn {
  background-color: transparent;
  border: none;
  display: block;
  text-align: center;
  font-size: 12px;
  font-family: "Avenir Next LT Pro, Demi";
  display: block;
  width: 100%;
  letter-spacing: 1.2px;
  color: #a7a7a6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  cursor: pointer;
}
.card .btn span {
  margin-right: 9px;
}

.card .btn span svg {
  width: 18px;
  height: 18px;
}

.card .btn:focus {
  outline: none;
}
.card .btn:hover {
  color: var(--main-orange);
}

.selected-sort-info {
  display: flex;
    flex-direction: column;
    justify-content: center;
  color: white;
  max-width: 4rem;
  text-align: center;
  padding-left: 1px;
  
  height: 2.7rem;
  background-color: var(--main-orange);
  border-radius: 4px;
 
  opacity: 1;
  position: relative;
  bottom: 3rem;
  left: 0.3rem;
}

.selected-sort-info p {

  font-size: 1rem;
  line-height: 0.8rem;
  font-family: "Avenir Next LT Pro, Demi";
  color: white;
  padding-top: 4px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.selected-sort-info p  span{
  font-size: 0.5rem;
  
}
