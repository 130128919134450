/*Variables*/

:root {
    --deg: 5deg;
    --base: #4d4d4d;
    --main-gray: #4d4d4d;
    --main-orange: #ff6b00;
    --trackball: var(--base);
    --range: 0%;
    --bg: #10252f;
    --deg2: 5deg;
    --base2: #a7a7a680;
    --second2: #ff6b00;
    --trackball2: var(--base);
    --range2: 0%;
    --bg2: #10252f;
}