.office-hours-banner{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4d4d4d;
        padding-top: 5px;
        padding-bottom: 5px;
}
.office-hours-copy{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.office-hours-copy label{
   margin-bottom: 0px;
   margin-left: 15rem;
   font:"Avenir Next LT Pro, Demi";
   color: #ffffff;
}
.office-hours-copy-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    padding-right: 10px;
}
.office-hours-copy-link-button {
   padding: 5px 12px 5px 12px;
   font-size: 10px;
   border-radius: 8px;
   background-color: #FFF0E5;
   color: #4d4d4d;
}
.office-hours-copy-link-button:hover {
    background-color: #FFF0E5;
    color: #4d4d4d;
}

.office-hours-banner .banner-close-icon-container {
    display: flex;
    justify-content: flex-end;
}
.office-hours-banner .banner-close-icon-container .banner-close-icon {
    color:white;
    width: 20px;
    height: 20px;
    cursor: pointer;
}