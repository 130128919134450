
.payment--container {
    padding-bottom: 80px;
    max-width: 1005px;
    margin: 0 auto;
}
.payment--container .grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 90px;
}

.payment--container .grid .checkout {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

.payment--container .grid .checkout p {
    font-size: 20px;
    line-height: 30px;
    color: #4d4d4d;
    font-weight: 300;
    font-family: "Avenir Next LT Pro, Regular";
}


.payment--container .grid .promo .promo--container {
    display: flex;
    flex-direction: column;
    max-width: 493px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.25);
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.payment--container .grid .promo .promo--container .title {
    padding: 32px 32px;
    background-color: #FFF0E4;
    color: #FF6B03;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    justify-content: center;
    display: flex;
    font-family: "Avenir Next LT Pro, Demi";
}
.payment--container .grid .promo .promo--container .content {
    padding: 40px 35px;
}
.payment--container .grid .promo .promo--container .content p {
    font-size: 20px;
    line-height: 30px;
    color: #4d4d4d;
    font-weight: 300;
    margin-bottom: 38px;
    font-family: "Avenir Next LT Pro, Regular";
}
.payment--container .grid .promo .promo--container .content h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #333333;
    font-family: "Avenir Next LT Pro, Demi";
}
.payment--container .grid .promo .promo--container .content button {
    height: max-content;
    padding: 18px 56px;
    margin-top: 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    width: max-content;
}

.payment--container .grid .promo .promo--container .content input.uk-input.input-box {
    border-color:#4D4D4D;
    padding-left: 26px;
    padding-right: 26px;
    height: 65px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
}
.payment--container .grid .promo .promo--container .content.success input.uk-input.input-box {
    border-color: #3D9941;
}
.payment--container .grid .promo .promo--container .content.success button {
    background-color: #3D9941;
}

.payment--container .description {
    max-width: 429px;
}
.payment--container .description p {
    margin-bottom: 32px;
    max-width: 391px;
    margin-top: 0px;
}
.payment--container .description h3 {
    font-size: 40px;
    line-height: 40px;
    font-family: "Avenir Next LT Pro, Demi";
    margin-bottom: 32px;
}
.payment--container .uk-flex .uk-width-1-2 {
    display: flex;
    justify-content: center;
}

.payment--container .justify-space-between {
    display: flex;
    justify-content: space-between;
}

.payment--container .content .justify-space-between {
    align-items: center;
    margin-top: 24px;
}

.payment--container .content .justify-space-between h4 {
    font-size: 24px;
    line-height: 24px;
    color: #3D9941;
    display: none;
}


.payment--container .content.content.success .justify-space-between h4 {
    display: block;
}

.payment--container .justify-space-between.price {
    color: #FF6B00;
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
    font-family: "Avenir Next LT Pro, Regular";
}

.payment--container .justify-space-between.price + .price {
    margin-top: 16px;
}

.payment--container .price--table hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.payment--container .price--table {
    margin-bottom: 123px;
}

.payment--container .price--table + p {
    font-size: 16px !important;
    line-height: 30px !important;
    color: #4D4D4D;
    max-width: unset;
}

.payment--container .back {
    background-color: white;
    border: 1px solid #707070;
    color: #707070;
}
.payment--container .back .homelink__chevron {
    transform: rotate(180deg);
    filter: 
        invert(42%)
        sepia(0%)
        saturate(3%)
        hue-rotate(134deg)
        brightness(104%)
        contrast(90%);
}

.payment--container .buttons {
    display: flex;
    flex: row;
    gap: 42px;
    justify-content: center;
}

.payment--container .buttons .homelink.create-account {
    margin-top: 0;
    max-width: 347px;
    min-width: unset;
    width: 100%;
}


@media (min-width: 980px) {
    .payment--container .grid {
        grid-template-columns: 1fr 1fr;
    }
}