
table {
    border-collapse: collapse;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #99acc2;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

table th,td {
    border: 1px solid #E7E7E6;
    padding: 10px;
    color: #413F3B;
}