

/*--  Transition  --*/
/*--  Border Radius  --*/
/*--  Transform  --*/
/*--  Translate  --*/
/*--  Placeholder Color  --*/
/*--  Background Size  --*/
/*--  Circle --*/
/*--  Box Shadow  --*/
/*--  User Select  --*/
/*--  appearance   --*/
/*-- linear gradient --*/
* {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.Campton-regular {
  font-weight: 300;
}

.Campton-bold {
  font-weight: 700;
}

.Campton-medium {
  font-weight: 600;
}

.form-container form input.form-control:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.btn:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}



input[type="checkbox"] {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media (min-width: 1300px) {
  .custom-container {
    max-width: 1280px !important;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .profile-edit-dashboard-container .container {
    max-width: 1280px !important;
  }
}

html, body {
  height: 100%;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  min-height: 100%;
}

p {
  font-family: 'AvenirNext-regular';
}