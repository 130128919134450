.resources .heading {
  box-shadow: 10px 3px 10px #e9e9e8;
}

.resources .heading .uk-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 54px;
  gap: 16px;
}

.resources h1,
.resources h2,
.resources h3 {
  font-family: "Avenir Next LT Pro, Demi";
  margin: 0;
}

.resources h1 {
  line-height: 72px;
  font-size: 60px;
}

.resources h2 {
  font-size: 42px;
  line-height: 42px;
}

.resources hr {
  border-color: #707070;
  margin-top: 24px;
  margin-bottom: 36px;
}

.resources p {
  font-family: "Avenir Next LT Pro, Regular";
  font-size: 22px;
  line-height: 30px;
  color: #4d4d4d;
}

.resources .uk-container.content {
  padding-top: 68px;
  padding-bottom: 68px;
}

.resources .orange-bg {
  background-color: #fffaf759;
}
.resources .gray-bg {
  background-color: #e5e4e5;
}

.big-item.img--container {
  border: 1px solid #707070;
}
.resources .grid-6-cols {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 30px;
  row-gap: 36px;
}

.resources .grid-6-cols .big-item {
  border: 1px solid #707070;
}

.resources .grid-6-cols .big-item + div h3 {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 16px;
}
.resources .grid-6-cols .big-item + div p {
  font-size: 20px;
  line-height: 24px;
}

.resources .post {
  padding: 0;
}

.resources .post img {
  width: 100%;
  object-fit: cover;
}
.resources .post .lock-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.resources .post .lock-icon > img {
  width: 32px;
  height: 32px;
}
.resources .post img.complete {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.resources .post img.logo-small {
  width: 65%;
  object-fit: contain;
}

.uk-container.content .bg-gray {
  background-color: #f6f6f6;
}
.uk-container.content .bg-orange {
  background-color: #fffaf7;
}
.uk-container.content .bg-yellow {
  background-color: #fffef099;
}
.uk-container.content .bg-blue {
  background-color: #64b5b11a;
}

.resources .post .img--container.office-hour {
  border: 5px solid var(--main-orange);
}

.resources .post .img--container.office-hour img {
  width: 90%;
}

.resources .post .img--container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  border: 1px solid #707070;
  overflow: hidden;
  min-height: 184px;
  position: relative;
}

.resources .post h3 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;
}
.resources .post p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .resources h2 {
    font-size: 32px;
    line-height: 32px;
  }
  .resources .post.big .img--container {
    max-height: 324px;
  }
  .resources .grid-6-cols .big-item {
    max-height: 502px;
  }
  .resources .grid-6-cols {
    column-gap: 16px;
    row-gap: 24px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .resources .grid-2-cols {
    display: grid;
    column-gap: 30px;
    row-gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: 46px;
  }
  .resources .grid-2-cols .big-item + div h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .resources .grid-2-cols .big-item + div p {
    font-size: 18px;
    line-height: 20px;
  }

  .resources .post .img--container {
    max-height: 184px;
    margin-bottom: 16px;
    height: 100%;
  }
  .resources .post h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .resources .post p {
    font-size: 12px;
    line-height: 16px;
  }
  .resources .grid-6-cols .big-item + div h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .resources .grid-6-cols .big-item + div p {
    font-size: 18px;
    line-height: 20px;
  }
  .resources .uk-container.content {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .resources .post {
    grid-column: span 2 / span 2;
  }
  .resources .post.big {
    grid-column: span 3 / span 3;
  }
  .resources .grid-6-cols .big-item + div {
    grid-column: span 2 / span 2;
    padding-top: 46px;
  }
  .resources .grid-6-cols .big-item {
    grid-column: span 4 / span 4;
  }
}

@media (min-width: 1024px) {
  .resources .post .img--container {
    max-height: 210px;
  }

  .resources .heading .uk-container {
    grid-template-columns: 1fr 1fr;
  }

  .resources .grid-6-cols {
    column-gap: 30px;
    row-gap: 36px;
  }
  .resources .post h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .resources .post p {
    font-size: 16px;
    line-height: 28px;
  }
  .resources h2 {
    font-size: 42px;
    line-height: 42px;
  }
  .resources .grid-6-cols .big-item + div h3 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 16px;
  }
  .resources .grid-6-cols .big-item + div p {
    font-size: 20px;
    line-height: 24px;
  }
  .resources .uk-container.content {
    padding-top: 68px;
    padding-bottom: 68px;
  }
}
.resources .blog-posts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.resources .blog-post {
  display: flex;
  background-color: white;
  padding: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .blog-post-link {
    max-width: 30%;
    padding: 0;
    justify-content: center;
    font-size: 0.8rem;
    border-radius: 5px;
    @media (max-width: 768px) {
        max-width: 100%;
    }
  }
}

.resources .blog-posts .blog-post h3 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;
}
.resources .blog-posts .blog-post p {
  font-size: 16px;
  line-height: 28px;
}
.resources .blog-post-img {
  width: 180px;
  height: 180px;
  margin-right: 20px;

  object-fit: cover;
}
.resources .blog-post-content {
  flex-grow: 1;
  margin-top: 10px;
}
.resources .expert-help {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.resources .expert-help h5 {
  text-align: center;
}
.resources .find-community-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.resources .find-community {
  max-width: 30%;
}
.resources .section-title {
  text-align: center;
}

.resources .how-to-use-values-text-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
