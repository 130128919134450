
.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: fadein 0.5s;
    flex-direction: column;
}

.modal-container .modal-content{
    width: 100%;
    min-width: 320px;
    max-width: 650px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    animation: fadein 0.5s;
}

.modal-container .modal-close-icon-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.modal-container .modal-close-icon-container .modal-close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.modal-container .modal-title {
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}
