.spinner-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 20px;
  }
  
  .spinner-loader img {
    max-width: 100%;
    width: 100px;
    height: 100px;
  }