/* CONTACT US */
.contact-us-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 40px;
    gap: 32px;
}
.contact-us-grid hr {
    background-color: #707070;
    height: 1px;
    width: 100%;
    border: none;
}
.contact-us-grid hr.vertical {
    display: none;
    width: 1px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}
.contact-us-grid .grid--item {
    padding: 24px 0;
}
.contact-us-grid .grid--item p {
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    font-family: "Avenir Next LT Pro, Regular";
}
.contact-us-grid .grid--item p.main {
    font-size: 20px;
    line-height: 32px;
}

.lined-title--container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: 16px;
    margin-bottom: 50px;
}
.lined-title--container h3 {
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    letter-spacing: 1.6px;
    color: #4d4d4d;
    text-transform: uppercase;
}
.contact-us-grid .homelink.homelink--transparent {
    margin: 0;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    width: max-content;
    line-height: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.contact-us-grid .homelink.homelink--transparent + .lined-title--container {
    margin-top: 80px;
}

@media (min-width: 640px) {
    .contact-us-grid {
        gap: 32px;
    }
    .contact-us-grid .homelink.homelink--transparent + .lined-title--container {
        margin-top: 120px;
    }
}
@media (min-width: 980px) {
    .contact-us-grid {
        grid-template-columns: 1fr 234px 1fr;
    }
    .contact-us-grid hr.vertical {
        display: block;
    }
}