.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    justify-content: space-between;
    
  }
  .form input {
     width: 100%;
  }
  .form textarea {
    width: 100%;
  }
  