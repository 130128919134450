

/* FA Dashboard */
.FA-admin-container {
    padding-bottom: 160px;
    overflow: hidden;
  }
  
  ul.nav-list-dashboard {
    overflow: hidden;
    padding: 40px 0;
  }
  
  ul.nav-list-dashboard li {
    margin-right: 30px;
  }
  
  ul.nav-list-dashboard li a {
    padding: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #a3a3a3 !important;
  }
  
  ul.nav-list-dashboard li a:hover, ul.nav-list-dashboard li a:focus, ul.nav-list-dashboard li a.active {
    color: #e0601e !important;
  }
  
  .FA-form-container .FA-form .FA-form-fields {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
    position: relative;
    border-radius: 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form1 > div:nth-of-type(1), .FA-form-container .FA-form .FA-form-fields.FA-form2 > div:nth-of-type(1) {
    border-radius: 6px 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form1 > div:nth-of-type(1) .form-group, .FA-form-container .FA-form .FA-form-fields.FA-form2 > div:nth-of-type(1) .form-group {
    border-radius: 6px 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form1 > div:nth-last-of-type(1), .FA-form-container .FA-form .FA-form-fields.FA-form2 > div:nth-last-of-type(1) {
    border-radius: 0 0 6px 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form1 > div:nth-last-of-type(1) .form-group, .FA-form-container .FA-form .FA-form-fields.FA-form2 > div:nth-last-of-type(1) .form-group {
    border-radius: 0 0 6px 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section {
    height: auto;
    min-height: 7rem;
    border-left: 1px solid #d2d2d2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .form-group, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .form-group {
    width: 100%;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .form-group .form-control, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .form-group .form-control {
    height: calc(7rem - 30px);
    border: none;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .form-group label, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .form-group label {
    top: calc(50% + 15px);
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .form-group .field-focus, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .form-group .field-focus {
    padding-top: 35px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .form-group .field-focus + label, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .form-group .field-focus + label {
    top: 10px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .radio-buttons, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .radio-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-left: 30px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .radio-buttons div, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .radio-buttons div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 12px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .radio-buttons div:last-child, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .radio-buttons div:last-child {
    margin-right: 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .radio-buttons div .form-check-input, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .radio-buttons div .form-check-input {
    position: static;
    margin: 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .account-size-section .radio-buttons div span, .FA-form-container .FA-form .FA-form-fields.FA-form3 .account-size-section .radio-buttons div span {
    font-size: 12px;
    padding-left: 5px;
    margin-bottom: -3px;
    display: block;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .multiple-percentage-fields.percentage-field-na-option h3, .FA-form-container .FA-form .FA-form-fields.FA-form3 .multiple-percentage-fields.percentage-field-na-option h3 {
    margin-bottom: 15px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .multiple-percentage-fields.percentage-field-na-option .NA-container, .FA-form-container .FA-form .FA-form-fields.FA-form3 .multiple-percentage-fields.percentage-field-na-option .NA-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .multiple-percentage-fields.percentage-field-na-option .NA-container span, .FA-form-container .FA-form .FA-form-fields.FA-form3 .multiple-percentage-fields.percentage-field-na-option .NA-container span {
    font-size: 13px;
    padding-left: 10px;
    line-height: normal;
    font-weight: 700;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .multiple-percentage-fields.percentage-field-na-option.hide-percentage-field .percentage-field, .FA-form-container .FA-form .FA-form-fields.FA-form3 .multiple-percentage-fields.percentage-field-na-option.hide-percentage-field .percentage-field {
    display: none;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form2 .multiple-percentage-fields.percentage-field-na-option.hide-percentage-field .NA-container, .FA-form-container .FA-form .FA-form-fields.FA-form3 .multiple-percentage-fields.percentage-field-na-option.hide-percentage-field .NA-container {
    margin-bottom: 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form4 > div:nth-of-type(1) {
    border-radius: 6px 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form4 > div:nth-of-type(1) .form-group {
    border-radius: 6px 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form4 > div.multiselect-dropdown-container {
    border-radius: 0 0 6px 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form4 > div.multiselect-dropdown-container .dropdown-heading {
    border-radius: 0 0 6px 6px !important;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form5 .title-upload-files {
    border-radius: 6px 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form5 > div.input-type-file:nth-last-of-type(1) {
    border-radius: 0 0 6px 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form5 > div.input-type-file:nth-last-of-type(1) .form-group {
    border-radius: 0 0 6px 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form5 > div.input-type-file:nth-last-of-type(2) {
    border-radius: 0 0 0 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form5 > div.input-type-file:nth-last-of-type(2) .form-group {
    border-radius: 0 0 0 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 .three-fields-container::after, .FA-form-container .FA-form .FA-form-fields.FA-form3 .three-fields-container::before {
    content: " ";
    display: table;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 .three-fields-container::after {
    clear: both;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-of-type(1) {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-of-type(1) > div:nth-of-type(1) {
    border-radius: 6px 0 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-of-type(1) > div:nth-of-type(1) .form-group {
    border-radius: 6px 0 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-of-type(1) > div:nth-last-of-type(1) {
    border-radius: 0 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-of-type(1) > div:nth-last-of-type(1) .form-group {
    border-radius: 0 6px 0 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-last-of-type(1) {
    border-radius: 0 0 6px 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-last-of-type(1) > div:nth-of-type(1) {
    border-radius: 0 0 0 6px;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-last-of-type(1) > div:nth-of-type(1) .dropdown-heading {
    border-radius: 0 0 0 6px !important;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-last-of-type(1) > div:nth-last-of-type(1) {
    border-radius: 0 0 6px 0;
  }
  
  .FA-form-container .FA-form .FA-form-fields.FA-form3 > .three-fields-container:nth-last-of-type(1) > div:nth-last-of-type(1) .dropdown-heading {
    border-radius: 0 0 6px 0 !important;
  }
  
  .FA-form-container .FA-form .FA-form-fields::after, .FA-form-container .FA-form .FA-form-fields::before {
    content: " ";
    display: table;
  }
  
  .FA-form-container .FA-form .FA-form-fields::after {
    clear: both;
  }
  
  .FA-form-container .FA-form .form-group, .FA-form-container .FA-form .input-type-file, .FA-form-container .FA-form .container-SM-links {
    position: relative;
    width: 50%;
    float: left;
    margin: 0;
  }
  
  .FA-form-container .FA-form .form-group .form-control, .FA-form-container .FA-form .input-type-file .form-control, .FA-form-container .FA-form .container-SM-links .form-control {
    border: 1px solid #d2d2d2;
    border-top: 0;
    color: #e0601e;
    line-height: normal;
    height: 7rem;
    padding-left: 30px;
    padding-right: 10px;
    font-weight: 300;
    font-size: 14px;
    z-index: 9;
    background: none;
    position: relative;
    display: block;
    width: 100%;
    border-radius: 0;
  }
  
  .FA-form-container .FA-form .form-group .form-control:focus, .FA-form-container .FA-form .input-type-file .form-control:focus, .FA-form-container .FA-form .container-SM-links .form-control:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .FA-form-container .FA-form .form-group .form-control.field-focus + label, .FA-form-container .FA-form .input-type-file .form-control.field-focus + label, .FA-form-container .FA-form .container-SM-links .form-control.field-focus + label {
    top: 12px;
    margin-top: 0;
    line-height: 1;
    font-size: 11px;
    font-weight: 600;
    z-index: 9;
  }
  
  .FA-form-container .FA-form .form-group label, .FA-form-container .FA-form .input-type-file label, .FA-form-container .FA-form .container-SM-links label {
    font-size: 14px;
    color: #2c2c2c;
    line-height: normal;
    position: absolute;
    top: 50%;
    margin: -9px 0 0;
    left: 30px;
    -webkit-transition: 0.2s top linear;
    transition: 0.2s top linear;
    padding-right: 10px;
    font-weight: 300;
  }
  
  .FA-form-container .FA-form .form-group .error-message, .FA-form-container .FA-form .input-type-file .error-message, .FA-form-container .FA-form .container-SM-links .error-message {
    position: absolute;
    left: 30px;
    bottom: 10px;
    color: #d0021b;
    font-size: 11px;
    font-weight: 600;
    z-index: 9;
  }
  
  .FA-form-container .FA-form .form-group.textarea-input, .FA-form-container .FA-form .input-type-file.textarea-input, .FA-form-container .FA-form .container-SM-links.textarea-input {
    min-height: 7rem;
    border: 1px solid #d2d2d2;
    border-top: 0;
  }
  
  .FA-form-container .FA-form .form-group.textarea-input .form-control, .FA-form-container .FA-form .input-type-file.textarea-input .form-control, .FA-form-container .FA-form .container-SM-links.textarea-input .form-control {
    border: none;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    height: 56px;
  }
  
  .FA-form-container .FA-form .form-group.textarea-input .form-control::-webkit-scrollbar, .FA-form-container .FA-form .input-type-file.textarea-input .form-control::-webkit-scrollbar, .FA-form-container .FA-form .container-SM-links.textarea-input .form-control::-webkit-scrollbar {
    width: 0;
  }
  
  .FA-form-container .FA-form .form-group.input-full-line, .FA-form-container .FA-form .input-type-file.input-full-line, .FA-form-container .FA-form .container-SM-links.input-full-line {
    width: 100% !important;
  }
  
  .FA-form-container .FA-form .form-group .form-control:nth-child(odd), .FA-form-container .FA-form .input-type-file .form-control:nth-child(odd), .FA-form-container .FA-form .container-SM-links .form-control:nth-child(odd) {
    border-right: 0;
  }
  
  .FA-form-container .FA-form .input-type-file {
    position: relative;
  }
  
  .FA-form-container .FA-form .input-type-file .thumbnail-img {
    width: auto !important;
    height: 101px;
    position: absolute;
    right: 20px;
    top: 6px;
    max-width: 100%;
    border: 1px solid #d2d2d2;
  }
  
  .FA-form-container .FA-form .input-type-file .edit-profile-icon {
    position: absolute;
    top: 11px;
    right: 25px;
    width: 25px;
    max-width: 100%;
  }
  
  .FA-form-container .FA-form .input-type-file .form-group {
    border-left: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    position: relative;
    float: none;
    width: 100%;
  }
  
  .FA-form-container .FA-form .input-type-file input[type="file"] {
    opacity: 0;
    z-index: 2;
    position: relative;
    cursor: pointer;
    border: none;
    height: calc(7rem - 1px);
  }
  
  .FA-form-container .FA-form .input-type-file #selected-file-name, .FA-form-container .FA-form .input-type-file #text-choose-file {
    font-size: 14px;
    color: #e0601e;
    position: absolute;
    left: 30px;
    top: 50%;
    margin-top: -10px;
    line-height: normal;
  }
  
  .FA-form-container .FA-form .input-type-file #text-choose-file {
    color: #2c2c2c;
    left: auto;
    right: 20px;
  }
  
  .FA-form-container .FA-form .container-SM-links {
    width: 100%;
    border-bottom: 1px solid #d2d2d2;
  }
  
  .FA-form-container .FA-form .container-SM-links .container-add-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f7f7f7;
  }
  
  .FA-form-container .FA-form .container-SM-links .container-add-social .btn-add-another-social {
    color: #e0601e;
    background: transparent;
    border-color: transparent !important;
    padding: 5px 40px;
    cursor: pointer;
  }
  
  .FA-form-container .FA-form .container-SM-links .container-add-social .btn-add-another-social:hover, .FA-form-container .FA-form .container-SM-links .container-add-social .btn-add-another-social:focus, .FA-form-container .FA-form .container-SM-links .container-add-social .btn-add-another-social:active {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  
  .FA-form-container .FA-form .container-SM-links .container-add-social h3 {
    border: 0;
    width: 60%;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-container {
    border-top: 1px solid #d2d2d2;
    position: relative;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-container::before, .FA-form-container .FA-form .container-SM-links .SM-links-container::after {
    display: table;
    content: "";
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-container::after {
    clear: both;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-left, .FA-form-container .FA-form .container-SM-links .SM-links-right {
    float: left;
    width: 50%;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-left .form-group, .FA-form-container .FA-form .container-SM-links .SM-links-right .form-group {
    height: auto;
    border: none;
    width: 100%;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-center {
    float: unset;
    width: 100%;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-center .form-group {
    height: auto;
    border: none;
    width: 100%;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-right {
    width: 35%;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-right .form-group::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 3rem;
    left: 0;
    top: 2rem;
    background-color: #d2d2d2;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-right .form-control {
    border: none;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-links-left {
    height: 7rem;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-button-container {
    float: left;
    width: 15%;
    padding: 40px 0 0 10px;
  }
  
  .FA-form-container .FA-form .container-SM-links .SM-button-container button {
    padding: 0;
    background: none;
    color: #e0601e;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    border: none;
    line-height: normal;
    cursor: pointer;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-container.focused-select + label, .FA-form-container .FA-form .container-SM-links .select-container.field-focus + label {
    top: 10px !important;
    font-size: 11px;
    margin-top: 0;
    font-weight: 600;
    z-index: 9;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__control {
    border: none;
    min-height: 7rem;
    background-color: transparent !important;
    padding-right: 0 !important;
    padding-left: 20px;
    width: 90%;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__control--is-focused {
    border: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__single-value {
    color: #e0601e !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: normal;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__indicator-separator {
    display: none;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__dropdown-indicator {
    position: relative;
    padding: 0 !important;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__dropdown-indicator:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 8px;
    margin-top: -5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #e0601e;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__dropdown-indicator svg {
    display: none;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__menu {
    background-color: #FFFFFF;
    margin-top: 0;
    z-index: 9999;
    width: 90%;
    left: 30px;
    top: 1px;
    border-radius: 0;
    z-index: 99999;
    position: relative;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__menu::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 27px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 5px solid black;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
            box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__menu-list {
    padding: 0;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__menu-list .select-dropdown__option {
    background: none;
    border-bottom: 1px solid #d2d2d2;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__menu-list .select-dropdown__option.select-dropdown__option--is-selected {
    display: none;
  }
  
  .FA-form-container .FA-form .container-SM-links .select-dropdown__menu-list .select-dropdown__option:nth-last-of-type(1) {
    border-bottom: 0;
  }
  
  .buttons-container-form {
    margin-top: 1rem;
    float: right;
    overflow: hidden;
  }
  
  .buttons-container-form .btn.btn-in-fa-form {
    height: 57px;
    width: 208px;
    border: 2px solid #e0601e !important;
    background-color: transparent;
    color: #e0601e;
    font-weight: 600;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    float: left;
    margin-right: 20px;
  }
  
  .buttons-container-form .btn.btn-in-fa-form:last-child {
    margin-right: 0;
  }
  
  .buttons-container-form .btn.btn-in-fa-form:hover {
    background-color: #e0601e !important;
    color: #fff;
  }
  
  .buttons-container-form .fa-submit-btn {
    background-color: #e0601e !important;
    color: #fff !important;
  }
  
  .buttons-container-form .fa-cancel-btn {
    background-color: transparent !important;
    border: none !important;
    color: #6c757d !important;
    float: left;
    margin-right: 20px;
    height: 57px;
    width: 208px;
    font-weight: 600;
    text-decoration: underline;
  }
  
  .buttons-container-form .fa-cancel-btn-modal {
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    float: left;
    margin-right: 20px;
    height: 57px;
    width: 208px;
    font-weight: 600;
    text-decoration: underline;
  }
  
  .multiselect-dropdown-container {
    clear: both;
    position: relative;
    border-left: 1px solid #d2d2d2;
  }
  
  .multiselect-dropdown-container .label-name {
    font-size: 14px;
    color: #2c2c2c;
    line-height: normal;
    position: absolute;
    top: 10%;
    margin: -9px 0 0;
    left: 30px;
    -webkit-transition: 0.2s top linear;
    transition: 0.2s top linear;
    font-weight: 300;
    margin-right: 70px;
    z-index: 0;
  }
  
  .multiselect-dropdown-container .error-message {
    position: absolute;
    left: 30px;
    bottom: 10px;
    color: #d0021b;
    font-size: 11px;
    font-weight: 600;
    z-index: 9;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-heading {
    height: 7rem !important;
    border: none !important;
    border-radius: 0 !important;
    color: #e0601e !important;
    background-color: transparent !important;
    cursor: pointer !important;
    z-index: 9 !important;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-heading .dropdown-heading-value {
    padding-left: 30px !important;
    top: 50% !important;
    margin-top: -10px;
    line-height: normal !important;
    font-size: 14px;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-heading .dropdown-heading-value span {
    margin-right: 70px;
    color: #e0601e !important;
    display: none;
    font-size: 14px;
    height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-heading .dropdown-heading-dropdown-arrow {
    position: relative;
    width: 50px !important;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-heading .dropdown-heading-dropdown-arrow:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 100%;
    top: 50%;
    margin-top: -5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #e0601e;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-heading .dropdown-heading-dropdown-arrow span {
    display: none !important;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content {
    position: relative;
    width: 320px !important;
    right: 0 !important;
    z-index: 9999 !important;
    top: 100% !important;
    border-radius: 4px;
    overflow-y: unset !important;
    margin-top: 0 !important;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 5px solid black;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
            box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-panel {
    overflow-y: scroll;
    max-height: 290px;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-panel label {
    margin-bottom: 0;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-item {
    color: #2c2c2c !important;
    padding: 10px 12px !important;
    background-color: transparent !important;
    border-bottom: 1px solid #d2d2d2;
    font-size: 14px;
    overflow: hidden;
    font-weight: 300;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-item input[type="checkbox"] {
    width: 17px;
    height: 17px;
    cursor: pointer;
    -webkit-appearance: checkbox !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    background-color: #e5e5e5;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-item .item-renderer {
    line-height: normal;
    display: block;
    overflow: hidden;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-item .item-renderer input {
    float: left;
    margin-top: 1px;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-item .item-renderer span {
    padding: 0 10px !important;
    float: left;
    width: 90%;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-list li .select-item {
    margin-bottom: 0;
  }
  
  .multiselect-dropdown-container .multi-select .dropdown-content .select-list li:last-child .select-item {
    border-bottom: 0;
  }
  
  .multiselect-dropdown-container.checked-values .label-name {
    top: 12px;
    margin: 0;
    line-height: 1;
    font-size: 11px;
    font-weight: 600;
  }
  
  .multiselect-dropdown-container.checked-values .dropdown-heading-value span {
    display: block !important;
  }
  
  .checkbox-large .dropdown-heading-dropdown-arrow {
    right: 350px;
  }
  
  .checkbox-large .multi-select .dropdown-content {
    right: 350px !important;
  }
  
  .dropdown-FA {
    position: relative;
    position: initial !important;
  }
  
  .dropdown-FA:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 100%;
    top: 50%;
    margin-top: -5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #e0601e;
  }
  
  .dropdown-FA::after {
    right: 40px;
  }
  
  .dropdown-FA .dropdown-menu {
    position: relative;
    width: 320px !important;
    right: 0 !important;
    z-index: 9999 !important;
    top: 100% !important;
    border-radius: 4px;
    -webkit-transform: unset !important;
            transform: unset !important;
    margin-top: 0 !important;
    left: auto !important;
    padding: 0 !important;
  }
  
  .dropdown-FA .dropdown-menu::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 5px solid black;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
            box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
  }
  
  .dropdown-FA .dropdown-menu .dropdown-item {
    color: #2c2c2c !important;
    padding: 10px 12px !important;
    background-color: transparent !important;
    border-bottom: 1px solid #d2d2d2;
    font-size: 14px;
    overflow: hidden;
    font-weight: 300;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 20px !important;
  }
  
  .dropdown-FA .dropdown-menu .dropdown-item input.form-check-input[type="radio"] {
    position: static;
    margin: 0 8px 0 0;
    width: 14px;
    height: 14px;
  }
  
  .dropdown-FA .dropdown-menu .dropdown-item label {
    margin: 0;
  }
  
  .dropdown-FA .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
  }
  
  .address-container .form-group {
    width: 25% !important;
  }
  
  .title-addresses {
    color: #2c2c2c;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    padding: 15px 15px 15px 30px;
    margin: 0;
    background-color: #f7f7f7;
    overflow: hidden;
    text-transform: uppercase;
  }
  
  .extra-addesses-container-outer {
    overflow: hidden;
  }
  
  .extra-address-container {
    overflow: inherit;
  }
  
  .extra-address-container .button-location-container-remove {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  
  .extra-address-container:nth-of-type(n+2) .button-location-container-remove {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 2px solid #d2d2d2;
  }
  
  .button-location-container {
    clear: both;
    overflow: hidden;
    border: 1px solid #d2d2d2;
    padding: 20px 20px 20px 30px;
    border-right: 0;
    border-top: 0;
  }
  
  .button-location-container button {
    padding: 0;
    background: none;
    color: #e0601e;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    border: none;
    line-height: normal;
    cursor: pointer;
  }
  
  .btn.btn-secondary.button-crop {
    margin: 20px auto 0;
    display: table;
    padding: 10px 30px;
    background: #e0601e;
    border: none;
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    border: none;
    line-height: normal;
    cursor: pointer;
  }
  
  .btn.btn-secondary.button-crop:hover, .btn.btn-secondary.button-crop:active {
    background: #e0601e !important;
    color: #FFFFFF;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
  }
  
  .delete-icon {
    height: 20px;
  }
  
  .input-thumbnail input[type="file"] {
    padding-right: 170px !important;
  }
  
  .text-count {
    position: absolute;
    display: block;
    right: 20px;
    top: 12px;
    font-size: 12px;
    font-weight: 700;
    color: #e0601e;
  }
  
  .pdf-download-text {
    color: #e0601e !important;
    font-size: 17px;
    font-weight: 500;
    display: table;
    margin: -20px 0 20px;
    text-decoration: none !important;
  }
  
  .three-fields-container {
    clear: both;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .three-fields-container > div {
    width: 33% !important;
    float: left;
    clear: none;
    border-bottom: 1px solid #d2d2d2;
  }
  
  .three-fields-container > div:nth-of-type(2) {
    width: 34% !important;
  }
  
  .three-fields-container > div .btn-secondary {
    display: block;
    width: 100%;
    background: none;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 0;
    color: #2c2c2c;
  }
  
  .three-fields-container > div .btn-secondary .form-group {
    width: 100% !important;
  }
  
  .three-fields-container > div .btn-secondary:hover, .three-fields-container > div .btn-secondary:active, .three-fields-container > div .btn-secondary:focus {
    background: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  
  .two-fields-container {
    clear: both;
  }
  
  .two-fields-container > div {
    width: 50% !important;
    float: left;
    clear: none;
    border-bottom: 1px solid #d2d2d2;
  }
  
  .two-fields-container > div .btn-secondary {
    display: block;
    width: 100%;
    background: none;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 0;
    color: #2c2c2c;
  }
  
  .two-fields-container > div .btn-secondary .form-group {
    width: 100% !important;
  }
  
  .two-fields-container > div .btn-secondary:hover, .two-fields-container > div .btn-secondary:active, .two-fields-container > div .btn-secondary:focus {
    background: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  
  .two-fields-container div.form-group {
    width: 100% !important;
  }
  
  .three-fields-container .dropdown-heading {
    border-bottom: none !important;
  }
  
  .multiple-percentage-fields,
  .radio-buttons-container,
  .container-SM-links {
    border-left: 1px solid #d2d2d2;
  }
  
  .radio-buttons-container h3,
  .radio-buttons-container h4,
  .radio-buttons-container span,
  .multiple-percentage-fields h3,
  .multiple-percentage-fields h4,
  .multiple-percentage-fields span {
    font-size: 16px;
    color: #2c2c2c;
    line-height: normal;
    font-weight: 300;
    margin: 0;
  }
  
  .radio-buttons-container h4,
  .multiple-percentage-fields h4 {
    font-size: 14px;
  }
  
  .radio-buttons-container h3,
  .multiple-percentage-fields h3 {
    font-size: 13px;
    line-height: normal;
    font-weight: 700;
  }
  
  .title-upload-files {
    font-size: 16px;
    color: #2c2c2c;
    line-height: normal;
    font-weight: 300;
    margin: 0;
    padding: 20px 20px 20px 30px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
  }
  
  .radio-buttons-container {
    height: 7rem;
    padding: 10px 30px;
    width: 50%;
    float: left;
    border-bottom: 1px solid #d2d2d2;
    position: relative;
  }
  
  .radio-buttons-container .error-message-radio {
    position: absolute;
    left: 30px;
    bottom: 10px;
    color: #d0021b;
    font-size: 11px;
    font-weight: 600;
    z-index: 9;
  }
  
  .radio-buttons-container .radio-buttons {
    overflow: hidden;
    padding-left: 3px;
  }
  
  .radio-buttons-container .radio-buttons div {
    float: left;
    min-width: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    line-height: normal;
  }
  
  .radio-buttons-container .radio-buttons div input[type="radio"] {
    width: 14px;
    height: 14px;
    position: static;
    margin: 0;
  }
  
  .radio-buttons-container .radio-buttons div label {
    margin: 0 0 0 8px;
    font-size: 14px;
  }
  
  .radio-buttons-container-firm {
    border-left: 1px solid #d2d2d2;
  }
  
  .radio-buttons-container-firm .radio-buttons div {
    min-width: 190px;
  }
  
  .multiple-percentage-fields {
    padding: 20px 30px;
    clear: both;
    border-bottom: 1px solid #d2d2d2;
    position: relative;
  }
  
  .multiple-percentage-fields h3 {
    margin-bottom: 35px;
  }
  
  .multiple-percentage-fields .error-message-percentage {
    position: absolute;
    top: 0;
    right: 0;
    color: #d0021b;
    font-size: 11px;
    font-weight: 600;
    width: 30%;
    display: block;
    text-align: right;
  }
  
  .multiple-percentage-fields .percentage-field {
    overflow: hidden;
    margin-bottom: 5px;
    position: relative;
  }
  
  .multiple-percentage-fields .percentage-field .error-message {
    display: none;
  }
  
  .multiple-percentage-fields .percentage-field h4,
  .multiple-percentage-fields .percentage-field div {
    float: left;
    width: 50%;
  }
  
  .multiple-percentage-fields .percentage-field .form-group {
    width: auto;
  }
  
  .multiple-percentage-fields .percentage-field .form-group .form-control {
    height: auto;
    border: none;
    text-align: right;
    padding: 10px 2px 10px 10px;
    width: auto;
  }
  
  .multiple-percentage-fields .percentage-field div {
    position: relative;
  }
  
  .multiple-percentage-fields .percentage-field div:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 60%;
    background-color: #2c2c2c;
    left: 0;
    top: 20%;
  }
  
  .multiple-percentage-fields .percentage-field div span {
    display: block;
    float: left;
    color: #e0601e;
    padding: 10px 0;
    font-size: 14px;
    line-height: normal;
  }
  
  .multi-select-single {
    width: 50%;
    float: left;
  }
  