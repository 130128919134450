.modal__container {
    opacity: 0;
    display: flex;
    transition: opacity 200ms ease;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
.modal__container::-webkit-scrollbar {
    display: none;
}

.modal__container.active {
    opacity: 1;
    pointer-events: all;
}

.modal__container .modal__content {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;  
    margin-right: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 990px;
    /* height: 449px; */
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 10px #4D4D4D66;
    border-radius: 10px;
    opacity: 1;
    position: relative;
    z-index: 2;
    padding: 22px;
    padding-bottom: 46px;
    padding-top: 50px;
}

.modal__container .modal__content .buttons__container {
    gap: 30px;
    display: flex;
    margin-left: auto;
    margin-top: 26px;
}
.modal__container .modal__content .buttons__container .homelink {
    height: 54px;
    width: 174px;
    font-family: "Avenir Next LT Pro, Demi";
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    align-items: center;
    font-weight: 300;
}
.modal__container .modal__content .buttons__container .homelink.alt {
    background-color: white;
    border: 2px solid #a7a7a6;
    color: #a7a7a6;
}

.modal__container .modal__content .buttons__container .homelink.alt:hover {
    background-color: #4d4d4d;
    border: 2px solid #4d4d4d;
    color: white;
}

.modal__container .client-type--container .grid {
    margin-bottom: 0;
}
.modal__container .background { 
    background: #4D4D4D 0% 0% no-repeat padding-box;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
}
.modal__container .modal__content h4 {
    text-align: left;
    letter-spacing: 1.6px;
    color: #4D4D4D;
    font-size: 16px;
    margin-bottom: 14px;
    line-height: 18px;
}
.modal__container .modal__content p {
    letter-spacing: 0px;
    color: #4D4D4D;
    line-height: 24px;
    font-size: 16px;
}
.modal__container .modal__content a.homelink {
    padding: 12px 42px;
    font-size: 18px;
    text-align: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    border-width: 1px;
    line-height: 1.2;
    margin-top: 65px;
}
.modal__container .modal__content .close {
    width: 28px;
    height: 28px;
    margin-bottom: 32px;
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 22px;
}
.modal__container .modal__content .close svg {
    width: 28px;
    height: 28px;
}


.switch--container {
    padding-top: 40px;
}
.client-type--container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.client-type--container .heading {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.client-type--container h3 {
    font-family: "Avenir Next LT Pro, Demi";
}

.client-type--container p {
    font-family: "Avenir Next LT Pro, Regular";
}


.client-type--container .heading h3 {
    margin-bottom: 20px;
    font-size: 32px;
    color: #4d4d4d;
}

.client-type--container .grid h3 {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
}

.client-type--container .grid hr {
    border-color: #707070;
    margin: 0;
}

.client-type--container .grid .active hr {
    border-color: var(--main-orange);
}

.client-type--container .grid .lined-title--container {
    align-items: center;
    gap: 22px;
}

.client-type--container .heading p:not(p.note) {
    font-size: 22px;
    line-height: 30px;
    font-weight: 200;
    color: #4d4d4d;
    margin-bottom: 0;
}

.client-type--container .heading p + p.note {
    margin-top: 20px;
}


.client-type--container .heading p.note {
    color: #a7a7a6;
    font-size: 16px;
    line-height: 24px;
    font-weight: 200;
}

.client-type--container .grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 55px;
}

.client-type--container .grid .grid-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0px 16px;
    position: relative;
}


.client-type--container .selected-client-type {
    background-color: #FFF0E5;
    color: var(--main-orange);
    padding: 6px 16px;
    font-size: 12px;
    text-transform: capitalize;
    width: max-content;
    opacity: 1;
    transition: opacity 200ms ease;
}

.client-type--container .grid-item.active  .selected-client-type {
    opacity: 1;
}

.client-type--container .grid-item:nth-child(2) .selected-client-type {
    left: 15px;
    top: 0; 
}


.client-type--container .current-client-type {
    background-color:  #E9E9E8;
    color: #A7A7A6;
    padding: 6px 16px;
    font-size: 12px;
    text-transform: capitalize;
    width: max-content;
}

.client-type--container .current-selected-client-type-tags{
    display: flex;
    flex-direction: row;
    gap: 10px;
    min-height: calc(14px + 16px);
}

.client-type--container .grid-item:nth-child(2) .current-client-type {
    left: 15px;
    top: 0; 
} 

.client-type--container .grid-item:nth-child(2) {
    margin-top: 16px;
}

.client-type--container .grid {
    padding: 0 20px;
}
.client-type--container .grid .grid-item * {
    filter: grayscale(100%) opacity(.6);
    transition: filter 200ms ease;
}

.client-type--container .grid .grid-item:hover *,
.client-type--container .grid .grid-item.active * {
    filter: grayscale(0%) opacity(1);
}

.client-type--container .grid .grid-item.active h3,
.client-type--container .grid .grid-item.active p {
    color: var(--main-orange);
}

.client-type--container .grid .lined-title--container {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 16px;
    color: #4d4d4d;
}

.client-type--container .grid .grid-item img {
    width: 320px;
}

.client-type--container .grid .client_type__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-type--container .grid .grid-item:nth-child(1) {
    align-items: center;
}

.client-type--container .grid .grid-item:nth-child(2) {
    align-items: center;
}

.client-type--container .grid .grid-item p {
    font-size: 16px;
    font-weight: 200;
    color: #4d4d4d;
    text-align: center;
}
.client-type--container .grid .grid-item:nth-child(1) p {
    max-width: 230px;
} 
.client-type--container .grid .grid-item:nth-child(2) p {
    max-width: 372px;
} 
.client-type--container .grid .lined-title--container hr {
    width: 50px;
}

.client-type--container .grid > :nth-child(1) {
    border-bottom: 1px solid #707070;
    border-right: unset;
}


@media (min-width: 768px) {
    .client-type--container .grid-item:nth-child(2) {
        margin-top: 0;
    }
    
    .client-type--container .grid > :nth-child(1) {
        border-right: 1px solid #707070;
        border-bottom: unset;
    }

    .client-type--container .heading {
        margin-bottom: 45px;
    }  

    .client-type--container .grid {
        grid-template-columns: 1fr 1fr;
        max-width: 838px;
        padding: 0;
    }

    .client-type--container .grid .grid-item {
        padding-bottom: 12px;
    }

    .client-type--container .heading {
        padding: 0;
    }
    .client-type--container .grid .grid-item:nth-child(1) {
        align-items: start;
    }
    
    .client-type--container .grid .grid-item:nth-child(2) {
        align-items: end;
    }
}

@media (min-width: 1024px) {
    .client-type--container .grid .grid-item {
        padding: 0px 0;
        padding-bottom: 32px;
    }
}

@media (min-width: 1200px) {
    .client-type--container .grid {
        grid-template-columns: 1fr 1fr;
    }
} 