.complete---container .description {
    max-width: 517px;
}

.complete---container .confirmation-title {
    text-align: center;
}

.complete---container .redirect-text {
    margin-top: 44px;
}

.complete---container .info-mail-link {
    text-decoration: underline;
    color:#4d4d4d;
    cursor: pointer;
    font-weight: bolder;
}

.complete---container .get-started-button {
    margin-top: 64px !important;
}

.complete---container {
    display: flex;
    gap: 55px;
    justify-content: center;
    padding-bottom: 75px;
}

.complete---container img {
    width: 473px;
    object-fit: contain;
    transform: translateY(-10%);
}


.complete---container h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 22px;
    font-family: 'Avenir Next LT Pro, Demi';
    color: #4d4d4d;
}

.complete---container h3 {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 22px;
    font-family: 'Avenir Next LT Pro, Regular';
    font-weight: 400;
    color: #4d4d4d;
}

.complete---container p {
    font-family: 'Avenir Next LT Pro, Regular';
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
    margin-bottom: 22px;
}

.complete---container a.homelink {
    font-family: 'Avenir Next LT Pro, Demi';
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    max-width: 346px;
    width: 100%;
    display: flex;
    height: 60px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
}
