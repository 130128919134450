.switch--container {
    padding-top: 40px;
}
.client-type--container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.client-type--container .heading {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.client-type--container h3 {
    font-family: "Avenir Next LT Pro, Demi";
}

.client-type--container p {
    font-family: "Avenir Next LT Pro, Regular";
}


.client-type--container .heading h3 {
    margin-bottom: 16px;
    font-size: 32px;
    color: #4d4d4d;
}

.client-type--container .grid h3 {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
}

.client-type--container .grid hr {
    border-color: #707070;
    margin: 0;
}

.client-type--container .grid .active hr {
    border-color: var(--main-orange);
}

.client-type--container .grid .lined-title--container {
    align-items: center;
    gap: 22px;
}

.client-type--container .heading p:not(p.note) {
    font-size: 22px;
    line-height: 30px;
    font-weight: 200;
    color: #4d4d4d;
    margin-bottom: 0;
}

.client-type--container .heading p + p.note {
    margin-top: 20px;
}


.client-type--container .heading p.note {
    color: #a7a7a6;
    font-size: 16px;
    line-height: 24px;
    font-weight: 200;
}

.client-type--container .grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 55px;
}

.client-type--container .grid .grid-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    position: relative;
}


.client-type--container .current--type {
    background-color: #FFF0E5;
    color: var(--main-orange);
    top: 0;
    right: 15px;
    position: absolute;
    padding: 6px 16px;
    font-size: 12px;
    text-transform: capitalize;
    width: max-content;
    opacity: 0;
    transition: opacity 200ms ease;
}

.client-type--container .grid-item.active  .current--type {
    opacity: 1;
}

.client-type--container .grid-item:nth-child(2) .current--type {
    left: 15px;
    top: 0; 
} 

.client-type--container .grid {
    padding: 0 20px;
}
.client-type--container .grid .grid-item * {
    filter: grayscale(100%) opacity(.6);
    transition: filter 200ms ease;
}

.client-type--container .grid .grid-item:hover *,
.client-type--container .grid .grid-item.active * {
    filter: grayscale(0%) opacity(1);
}

.client-type--container .grid .grid-item.active h3,
.client-type--container .grid .grid-item.active p {
    color: var(--main-orange);
}

.client-type--container .grid .lined-title--container {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 16px;
    color: #4d4d4d;
}

.client-type--container .grid .grid-item img {
    width: 320px;
}

.client-type--container .grid .client_type__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-type--container .grid .grid-item:nth-child(1) {
    align-items: center;
}

.client-type--container .grid .grid-item:nth-child(2) {
    align-items: center;
}

.client-type--container .grid .grid-item p {
    font-size: 16px;
    font-weight: 200;
    color: #4d4d4d;
    text-align: center;
}
.client-type--container .grid .grid-item:nth-child(1) p {
    max-width: 230px;
} 
.client-type--container .grid .grid-item:nth-child(2) p {
    max-width: 372px;
} 
.client-type--container .grid .lined-title--container hr {
    width: 50px;
}

.client-type--container .grid > :nth-child(1) {
    border-bottom: 1px solid #707070;
    border-right: unset;
}

.client-type--container .grid-item:nth-child(2) {
    margin-top: 16px;
}

@media (min-width: 768px) {
    .client-type--container .grid-item:nth-child(2) {
        margin-top: 0;
    }

    .client-type--container .grid > :nth-child(1) {
        border-right: 1px solid #707070;
        border-bottom: unset;
    }

    .client-type--container .heading {
        margin-bottom: 32px;
    }  

    .client-type--container .grid {
        grid-template-columns: 1fr 1fr;
        max-width: 936px;
        padding: 0;
    }

    .client-type--container .grid .grid-item {
        padding-bottom: 12px;
    }

    .client-type--container .heading {
        padding: 0;
    }
    .client-type--container .grid .grid-item:nth-child(1) {
        align-items: start;
    }
    
    .client-type--container .grid .grid-item:nth-child(2) {
        align-items: end;
    }
}

@media (min-width: 1024px) {
    .client-type--container .grid .grid-item {
        padding: 32px 0;
        padding-bottom: 32px;
    }
}

@media (min-width: 1200px) {
    .client-type--container .grid {
        grid-template-columns: 1fr 1fr;
    }
} 