html {
    scroll-padding-top: 32px;
    scroll-behavior: smooth;
}

.faq--container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    margin-top: 32px;
    margin-bottom: 32px;
}

.faq--container .index {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    filter:
        invert(78%)
        sepia(5%)
        saturate(51%)
        hue-rotate(22deg)
        brightness(86%)
        contrast(84%);
}

.faq--container img.main {
    margin-bottom: 16px;
}

.faq--container main h1 {
    font-size: 32px;
    line-height: 54px;
    text-align: center;
}

.faq--container main h3 {
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 25px;
}

.faq--container main hr,
.faq--container aside hr {
    border-color: #707070;
    margin-top: 32px;
    margin-bottom: 32px;
}

.faq--container main h4 {
    font-size: 16px;
    font-weight: 500;
} 

.faq--container main p {
    font-size: 16px;
    font-weight: 200;
    color: #4d4d4d;
}

.faq--container main li + li,
.faq--container aside li + li,
.faq--container main p + p {
    margin-top: 8px;
}
.faq--container main p + h4 {
    margin-top: 52px;
}
.faq--container aside h3 {
    font-size: 16px;
}
.faq--container aside ul a{
    font-weight: 200;
    color: #4d4d4d;
}

.faq--container aside h3,
.faq--container aside ul li {
    cursor: pointer;
}

.faq--container aside ul li {
    list-style-type: none;
}

.faq--container aside ul li::before {
    content: "•";
    color: transparent;
    display: inline-block;
    background-image: url('../../assets/images/chevron.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
    width: 1em;
    margin-left: -1em;
    position: relative;
    top: calc(1em / 4);
    left: -5px;
    filter: invert(67%) sepia(1%) saturate(205%) hue-rotate(202deg) brightness(99%) contrast(91%);
}

.faq--container aside h3:hover,
.faq--container aside ul li:hover {
    text-decoration: underline;
}

.faq--container img:not(.main, .inline) {
    margin-bottom: 16px;
}


@media (min-width: 768px) {
    .faq--container {
        grid-template-columns: 267px 1fr;
    }

    .faq--container aside {
        padding-right: 16px;
        border-right: 1px solid #707070;
    }
    .faq--container img.main {
        margin-bottom: 0px;
    }
}