.profile-submit-modal .modal-content {
    height: 100vh;
    background-color: none !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .profile-submit-modal .modal-content .modal-body {
    -webkit-box-flex: unset;
        -ms-flex: unset;
            flex: unset;
  }
  
  .profile-submit-modal .checkbox-container {
    background: #F7F7F7;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
    padding: 50px;
    width: 1000px;
    max-width: 1000px;
    max-height: 650px;
    overflow-y: auto;
  }
  
  .profile-submit-modal .checkbox-container .title-description {
    margin-bottom: 30px;
  }
  
  .profile-submit-modal .checkbox-container .title-description img {
    max-width: 256px;
    margin-bottom: 0;
  }
  
  .profile-submit-modal .checkbox-container .checkbox-description {
    width: 100%;
    margin: 0 auto 30px;
    color: #505050;
  }
  
  .profile-submit-modal .checkbox-container .checkbox-description ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .profile-submit-modal .checkbox-container .checkbox-description ul li {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding-bottom: 15px;
  }
  
  .profile-submit-modal .checkbox-container .checkbox-description ul li span {
    font-size: 14px;
    font-weight: 400;
  }
  
  .profile-submit-modal .checkbox-container .checkbox-description p {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0;
  }
  
  .profile-submit-modal .login-btn {
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    padding: 0.25rem 0.8rem;
    background-color: #e0601e !important;
    border: 2px solid #e0601e;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .profile-submit-modal .login-btn.accept-btn {
    width: 10%;
    border-color: #e0601e !important;
  }
  