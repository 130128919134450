.sortSelected{
    background: #A7A7A6 0% 0% no-repeat padding-box !important;
    color: #FFFFFF !important;
}

.sort-component-wrapper {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    align-items: baseline;
}

.formContainer button {
    border: none;
    font: normal normal normal 14px/18px "Avenir Next LT Pro, Demi";
    letter-spacing: 0px;
    color: #4D4D4D;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: block;
}
.formContainer > button:first-of-type {
    border-left: 0.5px solid #A7A7A6;

}
.formContainer > button:last-of-type {
    border-right: 0.5px solid #A7A7A6;
   
}